import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
   Alert,
   Box,
   Button,
   FormControl,
   FormGroup,
   IconButton,
   InputAdornment,
   InputLabel,
   OutlinedInput,
} from "@mui/material";
import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {useAuth} from "../Firebase";

const INITIAL_USER = {
   username: "",
   email: "",
   passwordOne: "",
   passwordTwo: "",
   isAdmin: false,
   error: null,
};

function SignUp() {
   const [newUser, setNewUser] = useState(INITIAL_USER);
   const [showPassword, setShowPassword] = useState(false);
   const [error, setError] = useState("");
   const {signUp} = useAuth();
   const isInvalid =
      newUser.passwordOne !== newUser.passwordTwo ||
      newUser.passwordOne === "" ||
      newUser.email === "" ||
      newUser.username === "";

   const navigate = useNavigate();
   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };
   const onSignUp = async (e) => {
      e.preventDefault();
      try {
         await signUp(newUser);
         navigate(ROUTES.HOME);
      } catch (err) {
         if (err.code === "auth/email-already-in-use") {
            setError("The Email is already in use.");
            return;
         }
         setError(err.message);
         console.error("Error Creating user:", err.code);
      }
   };

   function onChange(e) {
      setNewUser({
         ...newUser,
         [e.target.id]: e.target.value,
      });
   }
   function onChangeCheckbox(e) {
      setNewUser({
         ...newUser,
         isAdmin: e.target.checked,
      });
   }

   return (
      <Box py={2}>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="username">*Username</InputLabel>
            <OutlinedInput
               id="username"
               type={"text"}
               value={newUser.username}
               onChange={onChange}
               label="Username"
               size="small"
               required
               error={!newUser.username}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="email">*Email</InputLabel>
            <OutlinedInput
               id="email"
               type={"text"}
               value={newUser.email}
               onChange={onChange}
               label="Email"
               size="small"
               required
               error={!newUser.email}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="passwordOne">*Password</InputLabel>
            <OutlinedInput
               id="passwordOne"
               type={showPassword ? "text" : "password"}
               value={newUser.passwordOne}
               onChange={onChange}
               endAdornment={
                  <InputAdornment position="end">
                     <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                     >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                     </IconButton>
                  </InputAdornment>
               }
               label="Password"
               size="small"
               required
               error={!newUser.passwordOne}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="passwordTwo">*Password</InputLabel>
            <OutlinedInput
               id="passwordTwo"
               type={showPassword ? "text" : "password"}
               value={newUser.passwordTwo}
               onChange={onChange}
               endAdornment={
                  <InputAdornment position="end">
                     <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                     >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                     </IconButton>
                  </InputAdornment>
               }
               label="Confirm Password"
               size="small"
               required
               error={!newUser.passwordTwo}
            />
         </FormControl>
         <FormControl fullWidth sx={{m: 1, width: "35ch"}} variant="outlined">
            <FormGroup row sx={{justifyContent: "space-around"}}>
               {/* <FormControlLabel
                  control={<Switch checked={newUser.isAdmin} color="warning" onChange={onChangeCheckbox} />}
                  label="Admin"
               /> */}
               <Button onClick={onSignUp} disabled={isInvalid}>
                  Sign Up
               </Button>
            </FormGroup>
         </FormControl>
         {error ? <Alert severity="error">{error}</Alert> : ""}
      </Box>
   );
}

const SignUpPage = () => (
   <Box sx={{width: 358}} m="auto">
      <h1>SignUp</h1>
      <SignUp />
   </Box>
);
const SignUpLink = () => (
   <p>
      Don't have an account? <NavLink to={ROUTES.SIGN_UP}>Sign Up</NavLink>
   </p>
);
export default SignUpPage;
export {SignUp, SignUpLink};
