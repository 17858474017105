import GoogleIcon from "@mui/icons-material/Google";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {Alert, Box, Button, FormHelperText, styled} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {blue} from "@mui/material/colors";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {useAuth} from "../Firebase/";
import PasswordForgetPage from "../PasswordForget";
import {SignUpLink} from "../SignUp";

const MyButton = styled(Button)(() => ({
   backgroundColor: blue[500],
}));

const SignInPage = () => (
   <Box sx={{width: 358}} m="auto">
      <h1>Sign In</h1>
      <SignIn />
      <PasswordForgetPage />
      <SignUpLink />
   </Box>
);

function SignIn() {
   const [email, setEmail] = useState("");
   const [password, setPassowrd] = useState("");
   const [error, setError] = useState("");
   const [showPassword, setShowPassword] = useState(false);

   const {signIn, SignInWithGoogle} = useAuth();
   const isInvalid = email === "" || password === "";
   const navigate = useNavigate();

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };
   const onSignInWithGoogle = async (e) => {
      e.preventDefault();
      try {
         await SignInWithGoogle();
         navigate(ROUTES.HOME);
      } catch (err) {
         setError(err.message);
         console.error("Error for google Signing in:", err.message);
      }
   };

   const onSignIn = async (e) => {
      e.preventDefault();
      try {
         await signIn(email, password);
         navigate(ROUTES.HOME);
      } catch (err) {
         if (err.code === "auth/user-not-found" || err.code === "auth/wrong-password") {
            setError("Invalid Email or Password! Please try it Again.");
            return;
         }
         setError(err.message);
         console.error("Error code for Signing in:", err.code);
      }
   };

   return (
      <Box py={2}>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="email">*Email</InputLabel>
            <OutlinedInput
               id="email"
               type={"text"}
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               label="Email"
               size="small"
               required
               error={!email}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="password">*Password</InputLabel>
            <OutlinedInput
               id="password"
               type={showPassword ? "text" : "password"}
               value={password}
               onChange={(e) => setPassowrd(e.target.value)}
               endAdornment={
                  <InputAdornment position="end">
                     <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                     >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                     </IconButton>
                  </InputAdornment>
               }
               label="Password"
               size="small"
               required
               error={!password}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <Button onClick={onSignIn} disabled={isInvalid}>
               Sign In
            </Button>
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <MyButton endIcon={<GoogleIcon />} onClick={onSignInWithGoogle}>
               Sign In With
            </MyButton>
         </FormControl>
         <FormHelperText>*Fields are required.</FormHelperText>

         {error ? <Alert severity="error">{error}</Alert> : ""}
      </Box>
   );
}
export default SignInPage;
