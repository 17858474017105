import {Alert, Box, Button, FormControl, FormGroup, Grid, MenuItem, TextField} from "@mui/material";
import React, {useState} from "react";
import {v4 as uuidv4} from "uuid";
import * as DBBooks from "../../entities/books";
import * as Storage from "../Firebase/firebaseStorage";

const init_book = {
   name: "",
   price: "",
   currency: "USD",
   level: "Preschool to Elementary",
   description: "",
};

const path = "books/images/";
function BookEdit() {
   const [book, setBook] = useState(init_book);
   const [imageFile, setImageFile] = useState(null);

   const [error, setError] = useState("");
   const isInvalid = book.name === "" || book.level === "" || book.price === "" || book.currency === "";
   const onFileChoose = (event) => {
      setImageFile(event.target.files[0]);
   };

   const onChange = (e) => {
      console.log(e.target.name + ":" + e.target.value);
      setBook({
         ...book,
         [e.target.name]: e.target.value,
      });
   };

   const onSave = async () => {
      try {
         if (imageFile == null) return;
         const filename = uuidv4() + "." + imageFile.name.split(".").pop();
         console.log(filename);
         const result = await Storage.upload(`${path}${filename}`, imageFile);
         const downloadURL = await Storage.getURL(result.ref);
         const newBook = {...book, url: downloadURL};
         console.log(JSON.stringify(newBook));
         DBBooks.add(newBook);
      } catch (err) {
         throw err;
      }
   };
   const onModifyData = async () => {
      try {
         DBBooks.updateUrlForDevEnv();
      } catch (err) {
         throw err;
      }
       return true;
   };

   return (
      <Box py={2} component="form">
         <Grid container rowGap={1}>
            <Grid item xs={8}>
               <TextField fullWidth name="name" label="Name*" type={"text"} value={book.name} onChange={onChange} />
            </Grid>
            <Grid item xs={4}>
               <FormControl sx={{m: 0.5}} variant="outlined">
                  <label htmlFor="upload-image">
                     <input id="upload-image" accept="image/*" type="file" onChange={onFileChoose} />
                  </label>
               </FormControl>
            </Grid>
            <Grid item xs={8}>
               <TextField
                  fullWidth
                  name="level"
                  label="Level*"
                  type={"text"}
                  value={book.level}
                  onChange={onChange}
                  select
               >
                  <MenuItem value={"Preschool to Elementary"}>Preschool to Elementary</MenuItem>
                  <MenuItem value={"Elementary"}>Elementary</MenuItem>
                  <MenuItem value={"Middle School"}>Middle School</MenuItem>
                  <MenuItem value={"High School"}>High School</MenuItem>
                  <MenuItem value={"Supplementary"}>Supplementary</MenuItem>
               </TextField>
            </Grid>
            <Grid item xs={2}>
               <TextField
                  fullWidth
                  name="price"
                  label="Price*"
                  type={"number"}
                  value={book.price}
                  onChange={onChange}
               />
            </Grid>
            <Grid item xs={2}>
               <TextField fullWidth name="currency" value={book.currency} label="Currency" onChange={onChange} select>
                  <MenuItem value={"CAD"}>CAD</MenuItem>
                  <MenuItem value={"USD"}>USD</MenuItem>
               </TextField>
            </Grid>
            <Grid item xs={12}>
               <TextField
                  fullWidth
                  name="description"
                  label="Description"
                  value={book.description}
                  type={"text"}
                  multiline
                  rows={3}
                  onChange={onChange}
               />
            </Grid>
         </Grid>
         <FormControl fullWidth sx={{m: 1}} variant="outlined">
            <FormGroup row sx={{justifyContent: "space-around"}}>
               <Button onClick={onSave} disabled={isInvalid}>
                  Save
               </Button>
            </FormGroup>
         </FormControl>
         {/* <Grid item xs={12}>
               <Button onClick={onModifyData} disabled={true}>
                  Modify data for Dev environment
               </Button>
         </Grid> */}
         {error ? <Alert severity="error">{error}</Alert> : ""}
      </Box>
   );
}

export default BookEdit;
