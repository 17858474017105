import * as ROLES from "../../constants/roles";
import {useAuth} from "../Firebase";

function Authorized({role, children}) {
   const {dbUser} = useAuth();
   // const navigate = useNavigate();
   // useEffect(() => {
   //    if (!authUser) {
   //       console.log("Side Effect:You need to sign in first, please!");
   //       // navigate(ROUTES.SIGN_IN, {replace: true});
   //    } else {
   //       console.log("Side Effect: The authorized user:" + authUser.email);
   //    }
   // }, []);
   // console.log(`The signed in db user from authrized: ${JSON.stringify(dbUser)}.`);
   // console.log(`The signed in auth user from authrized: ${JSON.stringify(authUser)}.`);
   if (dbUser != null) {
      if (!role) {
         return children;
      } else {
         console.log(`The required role ${role} and user's role ${dbUser.roles[ROLES.ADMIN]}`);
         if (dbUser.roles[ROLES.ADMIN] != null && dbUser.roles[ROLES.ADMIN] === role) {
            console.log(`The authorized user: ${dbUser.email}  role: ${role}`);
            // navigate(ROUTES.SIGN_IN);
            return children;
         }
         console.log("You are not an admin user!");
         return <h1>You are not an admin user!</h1>;
      }
   } else {
      // navigate(ROUTES.SIGN_IN);
      return <h1>Please sign in first!</h1>;
   }
}
export default Authorized;
