import {Alert, Box, Button, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {useAuth} from "../Firebase";

const PasswordForgetPage = () => (
   <div>
      <h1>Password Forget</h1>
      <PasswordForget />
   </div>
);
const PasswordForgetLink = () => (
   <p>
      <NavLink to={ROUTES.PASSWORD_FORGET}>Forgot Password?</NavLink>
   </p>
);

function PasswordForget() {
   const navigate = useNavigate();
   const {resetPasword} = useAuth();

   const [email, setEmail] = useState("");
   const [error, setError] = useState(null);
   const isInvalid = email === "";

   const onPasswordForget = async (e) => {
      e.preventDefault();
      try {
         await resetPasword(email);
         navigate(ROUTES.SIGN_IN);
      } catch (err) {
         setError("Something went wrong, Please try it later.");
      }
   };

   const onChange = (e) => {
      setEmail(e.target.value);
   };

   return (
      <Box py={2}>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">*Email</InputLabel>
            <OutlinedInput
               id="outlined-adornment-email"
               type={"text"}
               value={email}
               onChange={onChange}
               label="Email"
               size="small"
               required
               error={!email}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <Button onClick={onPasswordForget} disabled={isInvalid}>
               Reset Password
            </Button>
         </FormControl>
         {error ? <Alert severity="error">{error}</Alert> : ""}
      </Box>
   );
}
export default PasswordForgetPage;
export {PasswordForget, PasswordForgetLink};
