import {
   signOut as authSignOut,
   createUserWithEmailAndPassword,
   onAuthStateChanged,
   sendEmailVerification,
   sendPasswordResetEmail,
   signInWithEmailAndPassword,
   signInWithPopup,
   updatePassword,
} from "firebase/auth";
import React, {createContext, useContext, useEffect, useState} from "react";
import * as ROLES from "../../constants/roles";
import * as DBUsers from "../../entities/users";
import {googleProvider, myAuth} from "./firebase";

const AuthUserContext = createContext();

export function AuthUserProvider({children}) {
   const [authUser, setAuthUser] = useState(null);
   const [dbUser, setDbUser] = useState(null);
   const [loading, setLoading] = useState(false);

   const clear = () => {
      setAuthUser(null);
      setDbUser(null);
      setLoading(false);
   };
   const changePassword = async (newPassword) => {
      if (!authUser) {
         try {
            await updatePassword(authUser, newPassword);
         } catch (err) {
            throw err;
         }
      } else {
         throw new Error("Please sign in first before changing password.");
      }
   };
   const signUp = async (newUser) => {
      try {
         const roles = {};
         if (newUser.isAdmin) {
            roles[ROLES.ADMIN] = ROLES.ADMIN;
         }
         const {user} = await createUserWithEmailAndPassword(myAuth, newUser.email, newUser.passwordOne);
         const dbUser = {
            username: newUser.username,
            email: user.email,
            roles: roles,
         };
         console.log("The signed up user:" + JSON.stringify(dbUser));
         DBUsers.add(user.uid, dbUser);
         await sendEmailVerification(user);
      } catch (err) {
         throw err;
      }
   };
   const resetPasword = async (email) => {
      try {
         await sendPasswordResetEmail(myAuth, email);
      } catch (err) {
         throw err;
      }
   };
   const signIn = async (email, password) => {
      try {
         const userCredential = await signInWithEmailAndPassword(myAuth, email, password);
         // console.log("Is this a new user? " + JSON.stringify(userCredential.user.toJSON()));
         if (userCredential.user.emailVerified) {
            console.log("The Signed in  & emailVerified User " + userCredential.user.email);
         } else {
            throw new Error("Please check you email and confirm verification. Thanks.");
         }
      } catch (err) {
         throw err;
      }
   };

   const SignInWithGoogle = async () => {
      try {
         const userCredential = await signInWithPopup(myAuth, googleProvider);
         // console.log("Is this a new user? " + JSON.stringify(userCredential.user.toJSON()));
         if (!userCredential.user.isAnonymous) {
            const user = userCredential.user;
            const roles = {};
            const dbUser = {
               username: user.displayName,
               email: user.email,
               roles: roles,
            };
            console.log("The google Signed in User " + JSON.stringify(dbUser));
            const docSnap = await DBUsers.getById(user.uid);
            if (!docSnap.exists()) {
               DBUsers.add(user.uid, dbUser);
               console.log("New user");
            }
         } else {
            throw new Error("Please sign in with Google first.");
         }
      } catch (err) {
         throw new Error(err);
      }
   };
   const signOut = async () => {
      await authSignOut(myAuth);
      clear();
   };

   const authStateChanged = async (user) => {
      if (!user || !user.emailVerified) {
         clear();
         return;
      }
      setAuthUser(user);
      setDbUser(null);
      setLoading(true);

      console.log(`auth state changes:${user.uid}/${user.email} `);

      try {
         const docSnap = await DBUsers.getById(user.uid);
         if (docSnap.exists()) {
            const dbUser = docSnap.data();
            console.log(dbUser);
            setDbUser(dbUser);
            setLoading(false);
         } else {
            throw new Error("The current user is not registered yet!");
         }
      } catch (err) {
         throw err;
      }
      // unsubDB = DBUsers.onDocChange(user.uid, (doc) => {
      //    if (doc.exists()) {
      //       console.error("The current user is not registered yet!");
      //       clear();
      //       return;
      //    }
      //    const authUser = doc.data();
      //    console.log(`firestore changes: ${JSON.stringify(doc.data())}.`);
      //    setAuthen({
      //       authUser,
      //       isLoading: false,
      //    });
      // });
   };

   useEffect(() => {
      const unsubAuth = onAuthStateChanged(myAuth, authStateChanged);
      return () => {
         unsubAuth();
      };
   }, []);

   return (
      <AuthUserContext.Provider
         value={{authUser, dbUser, loading, signUp, signIn, SignInWithGoogle, signOut, changePassword, resetPasword}}
      >
         {children}
      </AuthUserContext.Provider>
   );
}

export const useAuth = () => useContext(AuthUserContext);
