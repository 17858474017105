import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Button, Menu, MenuItem, Stack, Toolbar} from "@mui/material";
import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes";

function ManuBar() {
   const [schoolEl, setSchoolEl] = useState(null);
   const [booksEl, setBooksEl] = useState(null);
   const navigate = useNavigate();
   const openSchool = Boolean(schoolEl);
   const onSchoolClick = (e) => setSchoolEl(e.currentTarget);
   const onSchoolClose = (e) => setSchoolEl(null);
   const onClickSchoolMenuItem = (item) => {
      setSchoolEl(null);
      navigate(item);
   };
   const openBooks = Boolean(booksEl);
   const onBooksClick = (e) => setBooksEl(e.currentTarget);
   const onBooksClose = (e) => setBooksEl(null);
   const onClickBooksMenuItem = (item) => {
      setBooksEl(null);
      navigate(item);
   };

   return (
      <Toolbar>
         <Stack direction="row" spacing={2}>
            <Button variant="text" color="inherit" component={NavLink} to={ROUTES.HOME}>
               Home
            </Button>
            <Button
               variant="text"
               color="inherit"
               id="school-bt"
               onClick={onSchoolClick}
               aria-controls={openSchool ? "school-menu" : undefined}
               aria-haspopup="true"
               aria-expanded={openSchool ? "true" : undefined}
               endIcon={<KeyboardArrowDownIcon />}
            >
               Language School
            </Button>
            <Button
               variant="text"
               color="inherit"
               id="books-bt"
               onClick={onBooksClick}
               aria-controls={openBooks ? "books-menu" : undefined}
               aria-haspopup="true"
               aria-expanded={openBooks ? "true" : undefined}
               endIcon={<KeyboardArrowDownIcon />}
            >
               Better Chinese
            </Button>
            <Button variant="text" color="inherit" component={NavLink} to={ROUTES.CONSULTING}>
               Consulting
            </Button>
            <Button variant="text" color="inherit" component={NavLink} to={ROUTES.ABOUTUS}>
               About Us
            </Button>
            <Button variant="text" color="inherit" component={NavLink} to={ROUTES.CONTACTUS}>
               Contact Us
            </Button>
         </Stack>
         <Menu
            id="school-menu"
            anchorEl={schoolEl}
            open={openSchool}
            MenuListProps={{"aria-labelledby": "school-bt"}}
            onClose={onSchoolClose}
         >
            <MenuItem onClick={() => onClickSchoolMenuItem(ROUTES.OUR_INSTRUCTORS)}>Our Instructors</MenuItem>
            <MenuItem onClick={() => onClickSchoolMenuItem(ROUTES.OUR_CLASSES)}>Our Classes</MenuItem>
            <MenuItem onClick={() => onClickSchoolMenuItem(ROUTES.SCHOOL_EVENTS)}>School Events</MenuItem>
            <MenuItem onClick={() => onClickSchoolMenuItem(ROUTES.SUMMERCAMP)}>Summer Camp</MenuItem>
         </Menu>
         <Menu
            id="books-menu"
            anchorEl={booksEl}
            open={openBooks}
            MenuListProps={{"aria-labelledby": "books-bt"}}
            onClose={onBooksClose}
         >
            <MenuItem onClick={() => onClickBooksMenuItem(ROUTES.BOOKS_PRESCHOOL)}>Preschool to Elementary</MenuItem>
            <MenuItem onClick={() => onClickBooksMenuItem(ROUTES.BOOKS_ELEMENTARY)}>Elementary</MenuItem>
            <MenuItem onClick={() => onClickBooksMenuItem(ROUTES.BOOKS_MIDDLESCHOOL)}>Middle School</MenuItem>
            <MenuItem onClick={() => onClickBooksMenuItem(ROUTES.BOOKS_HIGHSCHOOL)}>High School</MenuItem>
            <MenuItem onClick={() => onClickBooksMenuItem(ROUTES.BOOKS_ONLINE)}>Online Learning</MenuItem>
            <MenuItem onClick={() => onClickBooksMenuItem(ROUTES.BOOKS_OTHERS)}>Supplementary</MenuItem>
         </Menu>
      </Toolbar>
   );
}

export default ManuBar;
