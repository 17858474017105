import * as DB from "../components/Firebase/firestore";

export const ENTITY = "images";

export function onColChange(doChange) {
   return DB.onChange(getImageColRef(), doChange);
}
export function getByfield(field, value) {
   return DB.getByfield(field, value, getImageColRef());
}
export function onDocChange(id, doChange) {
   // console.log("From Images:" + id);
   return DB.onChange(getImageDocRef(id), doChange);
}
export function getImageColRef() {
   return DB.getColRef(ENTITY);
}

export function getImageDocRef(id) {
   return DB.getDocRef(ENTITY, id);
}

export async function add(Image) {
   return DB.add(ENTITY, Image);
}

export async function getById(id) {
   return DB.getById(ENTITY, id);
}

export async function getImages() {
   return DB.get(ENTITY);
}
// Updates receipt with @docId with given information.
export async function update(id, data) {
   return DB.update(ENTITY, id, data);
}

// Deletes receipt with given @id.
export async function remove(id) {
   DB.remove(ENTITY, id);
}

export async function updateUrlForDevEnv() {
   DB.updateUrlForDevEnv(ENTITY);
}

