// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {GoogleAuthProvider, getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
   apiKey: "AIzaSyCvGB5k_kVp2GE1owSRxIpK2OGzAqTioic",
   authDomain: "alleasier.firebaseapp.com",
   projectId: "alleasier",
   storageBucket: "alleasier.appspot.com",
   messagingSenderId: "758261933028",
   appId: "1:758261933028:web:426d89f8efcc88e3ef1df1",
   measurementId: "G-YDW2YP69LL",
   databaseURL: "https://alleasier-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
export const myApp = initializeApp(config);
export const myAuth = getAuth(myApp);
export const googleProvider = new GoogleAuthProvider();
// export const db = getDatabase(app);
export const myFirestore = getFirestore(myApp);
export const myStorage = getStorage(myApp);
