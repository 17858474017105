import {Card, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {useAuth} from "../Firebase";

function Consulting() {
   const {dbUser} = useAuth();
   console.log(`The signed in user from home page: ${JSON.stringify(dbUser)}.`);
   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            BAIYI CONSULTING 百易移民咨询
         </Typography>
         <Typography gutterBottom variant="h6" component="div">
            Baiyi Consulting provides immigration services focused on International Students, PNP, Investment
            Immigration, Federal Skilled Worker and Family Sponsorship.
         </Typography>
         <Grid container spacing={2}>
            <Grid item xs={6}>
               <Card>
                  <CardMedia component="img" height="150" image="/consulting01.jpg" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        International Students
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Canada is known for its high quality education.Canadian international student enrollment has
                        grown from 136,000 in 2001, to over 265,000 in 2012, a 94% increase! International students come
                        from over 170 different countries all over the world. China represents almost 30% of the total
                        international enrollment in Canada. Chinese student enrollment in Canada has increased by about
                        300% since 2001. *Are you planning to study in Canada? Contact us to make your education dreams
                        come true!
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={6}>
               <Card>
                  <CardMedia component="img" height="150" image="/consulting02.png" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Immigration Service
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Baiyi Consulting is a team of immigration professionals. Our immigration lawyers and Canadian
                        Licensed Immigration Consultants are dedicated to guide you through the application process. Our
                        services do not stop at the point of receiving visa but also extend to your new life in Canada.
                        We will serve all your immigration and settlement needs. Our areas of expertise are Investment
                        Immigration, PNP, Federal Skilled Worker and Family Sponsorship. Contact us to book a
                        consultation to assess your qualifications
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={6}>
               <Card>
                  <CardMedia component="img" height="150" image="/consulting03.jpg" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Investment and Financing
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Senior financial advisers will be patiently consulting you on investment projects in the areas
                        of your interests, introducing popular financial products to increase your investment profits.
                        We offer VIP planning for your business visit, personal trip and local guidance as well as
                        services make your trip unforgettable. Contact us for details.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={6}>
               <Card>
                  <CardMedia component="img" height="150" image="/consulting04.jpg" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        2017 Canadian Summer Camp Recruiting Starts Now!
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        For students from China, tours to Canada East & West Coast included. Contact us for details.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
         </Grid>
      </Container>
   );
}

export default Consulting;
