import {Container, createTheme, ThemeProvider} from "@mui/material";
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import AboutUs from "../AboutUs";
import AccountPage from "../Account";
import {AdminBookPage, AdminImagePage, AdminUserPage} from "../Admin";
import Authorized from "../Authorized";
import {BookListPage, Online} from "../BooksPage";
import BreadcrumbsBar from "../BreadcrumbsBar";
import Consulting from "../Consulting";
import ContactUs from "../ContactUs";
import HomePage from "../Home";
import Instructors from "../Instructors";
import Navigation from "../Navigation";
import NotFound from "../NotFound";
import PasswordForgetPage from "../PasswordForget";
import SchoolGalleryPage from "../SchoolGallery";
import SignInPage from "../SignIn";
import SignUpPage from "../SignUp";
import "./App.css";

const myTheme = createTheme({
   components: {
      MuiButton: {
         styleOverrides: {
            root: {
               borderRadius: 8,
            },
         },
         defaultProps: {
            size: "small", // Set the default size to small
            variant: "contained",
         },
      },
      MuiTextField: {
         defaultProps: {
            size: "small", // Set the default size to small
         },
      },
   },
});

const App = () => (
   <BrowserRouter>
      <ThemeProvider theme={myTheme}>
         <Container sx={{maxWidth: 988}}>
            <Navigation />
            <BreadcrumbsBar />
            <Routes>
               <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
               <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
               <Route path={ROUTES.CONSULTING} element={<Consulting />} />
               <Route path={ROUTES.ABOUTUS} element={<AboutUs />} />
               <Route path={ROUTES.CONTACTUS} element={<ContactUs />} />
               <Route path={ROUTES.OUR_INSTRUCTORS} element={<Instructors />} />
               <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage />} />
               <Route path={ROUTES.SCHOOL_GALLERY} element={<SchoolGalleryPage />} />
               <Route path={ROUTES.BOOKS} element={<BookListPage />} />
               <Route path={ROUTES.BOOKS_ONLINE} element={<Online />} />
               <Route path={ROUTES.HOME} element={<HomePage />} />
               <Route
                  path={ROUTES.ACCOUNT}
                  element={
                     <Authorized>
                        <AccountPage />
                     </Authorized>
                  }
               />
               <Route
                  path={ROUTES.ADMIN_BOOKS}
                  element={
                     <Authorized role={ROLES.ADMIN}>
                        <AdminBookPage />
                     </Authorized>
                  }
               />
               <Route
                  path={ROUTES.ADMIN_IMAGES}
                  element={
                     <Authorized role={ROLES.ADMIN}>
                        <AdminImagePage />
                     </Authorized>
                  }
               />
               <Route
                  path={ROUTES.ADMIN_USERS}
                  element={
                     <Authorized role={ROLES.ADMIN}>
                        <AdminUserPage />
                     </Authorized>
                  }
               />
               <Route path="*" element={<NotFound />} />
            </Routes>
         </Container>
      </ThemeProvider>
   </BrowserRouter>
);
export default App;
