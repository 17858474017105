import {getDownloadURL, listAll, ref, uploadBytes} from "firebase/storage";
import {myStorage} from "./firebase";

// Name of receipt collection in Firestore

/* 
 Adds receipt to Firestore with given receipt information:
 - address: address at which purchase was made
 - amount: amount of expense
 - date: date of purchase
 - imageBucket: bucket at which receipt image is stored in Firebase Storage
 - items: items purchased
 - locationName: name of location
 - uid: user ID who the expense is for
*/
export function getRef(path) {
   return ref(myStorage, path);
}

export function upload(path, file) {
   return uploadBytes(ref(myStorage, path), file);
}

export function getURL(ref) {
   return getDownloadURL(ref);
}

export function list(path) {
   return listAll(ref(myStorage, path));
}
