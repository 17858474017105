import {Container, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as DBImages from "../../entities/images";
import SchoolGallery from "./SchoolGallery";

function SchoolGalleryPage() {
   let {type} = useParams();
   const [loading, setLoading] = useState(true);
   const [images, setImages] = useState(null);
   if (type.indexOf("-")) {
      type = type.split("-").join(" ");
   }
   useEffect(() => {
      getImages();
   }, [type]);

   const getImages = async () => {
      const result = await DBImages.getByfield("type", type);
      setImages(result);
      setLoading(false);
   };
   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            {type}
         </Typography>
         {loading ? <p>Loading</p> : <SchoolGallery images={images} />}
      </Container>
   );
}

export default SchoolGalleryPage;
