import {
   addDoc,
   collection,
   deleteDoc,
   doc,
   getDoc,
   getDocs,
   onSnapshot,
   orderBy,
   query,
   serverTimestamp,
   setDoc,
   updateDoc,
   where,
   writeBatch,
} from "firebase/firestore";
import {myFirestore} from "./firebase";

// Name of receipt collection in Firestore

/* 
 Adds receipt to Firestore with given receipt information:
 - address: address at which purchase was made
 - amount: amount of expense
 - date: date of purchase
 - imageBucket: bucket at which receipt image is stored in Firebase Storage
 - items: items purchased
 - locationName: name of location
 - uid: user ID who the expense is for
*/

export function onChange(ref, doChange) {
   const q = query(ref, orderBy("createdAt", "desc"));
   const unsubDB = onSnapshot(q, doChange);
   return unsubDB;
}
export async function getByfield(field, value, ref) {
   const q = query(ref, where(field, "==", value));
   const querySnapshot = await getDocs(q);
   let list = [];
   querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      list.push({id: doc.id, ...doc.data()});
   });
   return list;
}

export function getColRef(entity) {
   return collection(myFirestore, entity);
}

export function getDocRef(entity, id) {
   return doc(myFirestore, entity, id);
}

export function addWithId(entity, id, data) {
   // console.log("From DB:" + id);
   return setDoc(doc(myFirestore, entity, id), data);
}
export function add(entity, data) {
   const timestamp = serverTimestamp();
   return addDoc(collection(myFirestore, entity), {...data, createdAt: timestamp});
}
export async function getById(entity, id) {
   // console.log("From DB:" + id);
   const docRef = doc(myFirestore, entity, id);
   return getDoc(docRef);
}

export async function get(entity) {
   return getDocs(collection(myFirestore, entity));
}

// Updates receipt with @docId with given information.
export function update(entity, id, data) {
   const timestamp = myFirestore.Timestamp.now();
   return updateDoc(doc(myFirestore, entity, id), {...data, updatedAt: timestamp});
}

// Deletes receipt with given @id.
export function remove(col, id) {
   return deleteDoc(doc(myFirestore, col, id));
}


export async function updateUrlForDevEnv(entity) {
   const batch = writeBatch(myFirestore);
   const docs = await getDocs(collection(myFirestore, entity));
   if(docs.empty){
      return true;
   }
   docs.forEach(
      document => {
         console.log(document);
         var dataForUpdate = document.data()
         console.log(dataForUpdate);
         const newUrl = dataForUpdate.url.replace('alleasier.appspot','alleasier-dev.appspot');
         batch.update(doc(myFirestore, entity, document.id),{"url": newUrl});
         // await updateDoc(doc,{"newUrl": newUrl});
         console.log(newUrl);
      }
   );
   batch.commit();
}