import {Container, Typography} from "@mui/material";
import React from "react";
import PasswordChange from "../PasswordChange";

const AccountPage = () => (
   <Container>
      <Typography gutterBottom variant="h4" component="div">
         Account
      </Typography>
      <PasswordChange />
   </Container>
);
export default AccountPage;
