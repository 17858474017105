import {Container, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import * as DBImages from "../../entities/images";
import ImageEdit from "./ImageEdit";
import ImagesList from "./ImageList";

function AdminImagePage() {
   const [loading, setLoading] = useState(true);
   const [images, setImages] = useState(null);
   useEffect(() => {
      const unsubDB = DBImages.onColChange((snapshot) => {
         setLoading(true);
         const imageList = snapshot.docs.map((doc) => {
            const image = {...doc.data(), id: doc.id};
            console.log(`The book : ${JSON.stringify(image)}`);
            return image;
         });
         setImages(imageList);
         setLoading(false);
      });
      if (!unsubDB) return () => unsubDB();
   }, []);

   const onDelete = async (id) => {};

   const onSave = async (id, data) => {};

   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            Images Admin Page
         </Typography>
         <ImageEdit />
         {loading ? <p>Loading</p> : <ImagesList images={images} />}
      </Container>
   );
}

export default AdminImagePage;
