import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {useAuth} from "../Firebase";

function SignOutButton() {
   const navigate = useNavigate();
   const {signOut} = useAuth();
   const onSignOut = async (e) => {
      e.preventDefault();
      try {
         await signOut();
         navigate(ROUTES.SIGN_IN);
      } catch (err) {
         console.error("Error for Signing out:", err.message);
      }
   };
   return <Button onClick={onSignOut}>Sign Out</Button>;
}
export default SignOutButton;
