import React from "react";
import ReactDOM from "react-dom/client";
// import AppTest from "./components/AppTest";
import App from "./components/App";
import {AuthUserProvider} from "./components/Firebase";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <AuthUserProvider>
         <App />
      </AuthUserProvider>
   </React.StrictMode>
);
if (module.hot) {
   module.hot.accept();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
