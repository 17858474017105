import {Card, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import React from "react";
function Home() {
   return (
      <Container>
         <Grid container spacing={1}>
            <Grid item container alignItems="center" justifyContent="center" xs={12}>
               <img src="/home.png" alt="home" />
            </Grid>

            <Grid item xs={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardMedia component="img" height="150" image="/home01.jpg" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Mandarin School
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        All Easier (Bai Yi) Mandarin is a school that provides customized Mandarin language programs for
                        all ages and levels. Whether you want in-depth learning or basic Mandarin for business expansion
                        and travel purposes, we can tailor a program to meet your needs. Our flexible in-class Mandarin
                        lessons and online courses will ensure you improve your language skills in no time. Our
                        experienced teachers will patiently guide you to achieve your learning goals. Find out more
                        about our programs and services at Language School
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardMedia component="img" height="150" image="/home02.jpg" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Better Chinese Canada
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        We are pleased to announce that All Easier Corp has become the Better Chinese Canadian
                        Distributor! We provide the best Mandarin learning and teaching materials, as well as access to
                        our online learning system, to Canadian schools and students. Check out our Better Chinese
                        bookstore page to find out the exciting books and learning materials!
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardMedia component="img" height="150" image="/home03.jpg" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Consulting
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Baiyi Consulting is a team of Canadian immigration lawyers and licensed immigration consultants.
                        We provide professional immigration-related services. With our assistance and helpful advice you
                        are one step away from making your dream come true. Contact us to book a consultation meeting.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
         </Grid>
      </Container>
   );
}

export default Home;
