import {Container, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import * as DBBooks from "../../entities/books";
import BookEdit from "./BookEdit";
import BookList from "./BookList";

function AdminBookPage() {
   const [loading, setLoading] = useState(true);
   const [books, setBooks] = useState(null);
   useEffect(() => {
      const unsubDB = DBBooks.onColChange((snapshot) => {
         setLoading(true);
         const bookList = snapshot.docs.map((doc) => {
            const book = {...doc.data(), id: doc.id};
            console.log(`The book : ${JSON.stringify(book)}`);
            return book;
         });
         setBooks(bookList);
         setLoading(false);
      });
      if (!unsubDB) return () => unsubDB();
   }, []);

   const onDelete = async (id) => {};

   const onSave = async (id, data) => {};

   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            Books Admin Page
         </Typography>
         <BookEdit />
         {loading ? <p>Loading</p> : <BookList books={books} />}
      </Container>
   );
}

export default AdminBookPage;
