import {ImageList, ImageListItem, ImageListItemBar} from "@mui/material";

function ImagesList({images}) {
   return (
      <ImageList cols={4}>
         {images.map((image) => (
            <ImageListItem key={image.url}>
               <img src={image.url} alt={image.title} loading="lazy" />
               <ImageListItemBar title={image.caption} />
            </ImageListItem>
         ))}
      </ImageList>
   );
}

export default ImagesList;
