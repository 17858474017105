import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {Box, Button, Container, Grid, Input, Paper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import * as DBUsers from "../../entities/users";
const AdminUserPage = () => <Admin />;

function UserCard({aUser, onDelete, onSave}) {
   const [username, setUsername] = useState(aUser.username);
   const [isEdit, setIsEdit] = useState(false);

   const onEdit = () => {
      setIsEdit(!isEdit);
   };
   const onChange = (e) => {
      setUsername(e.target.value);
   };
   return (
      <Grid item xs={4}>
         <Paper elevation={4}>
            <Box padding={2}>
               <Typography variant="subtitle1">ID: {aUser.id}</Typography>
               <Typography variant="subtitle1">E-Mail: {aUser.email}</Typography>
               <Typography variant="subtitle1">
                  Username:{" "}
                  {isEdit ? (
                     <Input name="username" value={username} onChange={onChange} type="text" placeholder="Username" />
                  ) : (
                     aUser.username
                  )}
               </Typography>
               <Typography variant="subtitle1">Roles: {JSON.stringify(aUser.roles)}</Typography>
               <Typography variant="subtitle1">
                  {isEdit ? (
                     <>
                        <Button
                           startIcon={<SaveIcon />}
                           onClick={() => {
                              onSave(aUser.id, {username: username});
                              setIsEdit(!isEdit);
                           }}
                        >
                           Save
                        </Button>
                        <Button
                           startIcon={<CancelIcon />}
                           onClick={() => {
                              setIsEdit(false);
                           }}
                        >
                           cancel
                        </Button>
                     </>
                  ) : (
                     <Button startIcon={<EditIcon />} onClick={() => onEdit(aUser.id)}>
                        Edit
                     </Button>
                  )}
               </Typography>
               <Typography variant="subtitle1"></Typography>
            </Box>
         </Paper>
      </Grid>
   );
}
function Admin() {
   const [loading, setLoading] = useState(true);
   const [users, setUsers] = useState(null);
   useEffect(() => {
      const unsubDB = DBUsers.onColChange((snapshot) => {
         setLoading(true);
         const userList = snapshot.docs.map((doc) => {
            const user = {...doc.data(), id: doc.id};
            console.log(`The user : ${JSON.stringify(user)}`);
            return user;
         });
         setUsers(userList);
         setLoading(false);
      });
      if (!unsubDB) return () => unsubDB();
   }, []);

   const onDelete = async (id) => {
      await DBUsers.remove(id);
   };

   const onSave = async (id, data) => {
      console.log("id:" + id + ", data:" + JSON.stringify(data));
      await DBUsers.update(id, data);
   };

   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            Users Admin Page
         </Typography>
         {/* {!loading && JSON.stringify(users)} */}
         {loading ? (
            <p>Loading</p>
         ) : (
            <Grid container spacing={3}>
               {users.map((user) => (
                  <UserCard key={user.id} aUser={user} onDelete={onDelete} onSave={onSave} />
               ))}
            </Grid>
         )}
      </Container>
   );
}
export default AdminUserPage;
