import {Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

function BookList({books}) {
   return (
      <Box py={2} component="form">
         <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
               <TableHead>
                  <TableRow>
                     <TableCell>Book Name</TableCell>
                     <TableCell align="right">Level</TableCell>
                     <TableCell align="right">Price</TableCell>
                     <TableCell align="right"></TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {books.map((book) => (
                     <TableRow key={book.name} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                        <TableCell component="th" scope="row">
                           {book.name}
                        </TableCell>
                        <TableCell align="right">{book.level}</TableCell>
                        <TableCell align="right">{book.price}</TableCell>
                        <TableCell align="right">
                           <Button>Edit</Button>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Box>
   );
}

export default BookList;
