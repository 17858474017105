import {Card, CardContent, Container, Stack, Typography} from "@mui/material";
import React from "react";

function AboutUs() {
   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            About Us
         </Typography>
         <Stack spacing={2}>
            <Card>
               <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                     All Easier Corporation has three divisions: All Easier Mandarin School, Better Chinese Canada and
                     Baiyi Consulting
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>All Easier (百易) Mandarin School: </b>started from private play groups in the west end of
                     Toronto in 2004. The director of All Easier (百易) Mandarin, Cynthia(Xin) Xu was a medical doctor
                     in mainland China. She immigrated to Canada in 2004 and started teaching Mandarin in the same year
                     with help from the Families with Children from China (FCC). Cynthia is a gifted teacher who
                     naturally loves children. She is passionate in promoting the Chinese language and culture to the
                     western world. Her programs are designed to teach Putonghua (Standard Mandarin), PinYin (Chinese
                     phonetic system), and simplified Chinese characters that are using by the official Chinese language
                     system. Cynthia believes learning through fun is the best method for young learners. Through
                     Cynthia's classes, games, songs & crafts she keeps students engaged from beginning to end. She
                     provides not only a professional relationship but a personal one with each individual student and
                     their families. She is not only a language instructor but also the face of China to her students
                     and their families.
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Better Chinese Canada:</b> All Easier Corporation become Canadian Distributor for Better Chinese
                     in 2012. Better Chinese is a leading Mandarin book publisher in United States, founded by educators
                     from Columbia University and the United Nations in 1997 with a mission to develop the best
                     materials and programs to teach the Chinese language and culture to non-native speakers. Better
                     Chinese is also a leader in helping students learn Chinese through technology. From our extensive
                     Better Chinese online learning system to the Chinese iPad classroom, we seek to make learning
                     Chinese fun and relevant to the digital generation. Since 2008, nine U.S. states have conducted
                     formal reviews for Chinese language materials that align and correlate to their state standards.
                     Better Chinese's curricular materials have been adopted and approved by all nine states. In Canada,
                     Better Chinese book series have been using by many Chinese immersion schools, Chinese-English
                     bilingual schools, private and public schools.
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Baiyi Consulting:</b>
                     Baiyi consulting is a team of immigration professionals. From immigration lawyers to licensed
                     immigration consultants, we are committed to providing a high standard of immigration services. Our
                     services focus on international students, Provincial Nominee Programs (PNP), Investment
                     Immigration, Federal Skilled Worker and Family Sponsorship. With the help of our consulting
                     services, many students have been accepted into the schools or universities of their dreams.
                     Families have successfully landed in Canada and started their happy new life. Our service does not
                     stop after the immigration status obtained, we continue to provide settlement services to help
                     students and new immigrants to adjust and transit smoothly to their new country.
                  </Typography>
               </CardContent>
            </Card>
            <Card>
               <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                     Our Mission
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     Our language school's goal is to teach the Chinese language and culture through various flexible
                     programs. From comprehensive weekend and weekday evening programs for young students, to condensed
                     courses for adults and business people. No matter what your learning interests are we welcome one
                     and all!
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     The mission of our book distribution division is to provide Canadian teachers and students the best
                     Mandarin teaching and learning materials available. We are thrilled to serve Canadian schools with
                     the most updated learning materials and technology.
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     Baiyi Consulting serves as a bridge for people who have a dream to study and live in this modern,
                     beautiful country. We provide the professional services you need to help you settle in this
                     country.
                  </Typography>
               </CardContent>
            </Card>
            <Card>
               <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                     Testimonials for Mandarin School
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     "Both our girls (ages 9 and 6) have been in Cynthia's Mandarin class for several years. Cynthia is
                     a dedicated, caring and loving teacher who goes the extra mile by continually coming up with new
                     teaching techniques to keep both girls interested and engaged in learning about their language and
                     culture. But she is more than just a teacher, to us she is part of our extended family of Chinese
                     adoptees." <b>-Rob M, Toronto (Father to Zoe 9, and Amy 6)</b>
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     "Both our daughters have been taking Mandarin lessons from Cynthia for over 5 years. We feel that
                     the basic instruction in Mandarin have given our girls a good grounding in the language. The
                     Mandarin lessons have taken the form of classroom instruction with printed texts, singing of songs,
                     games and coloring. Most recently our eldest daughter has been participating in online Mandarin
                     lessons as a way of continuing her studies without having to leave our home or drive to classes.
                     She has found them to be very beneficial with the added attraction of staying in touch with her
                     Mandarin classmates whom she made lasting friendships with. It has also introduced her to the
                     benefits of online instruction over the internet something I expect we will continue to see through
                     her education in other subjects and venues." <b>-Janice Mummery & Dave Hanson</b>
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     "We are very pleased to have Cynthia Xu as our daughter Lia's Mandarin teacher starting when Lia
                     was a toddler. Lia began her Mandarin studies as a young child, a time when it is easy to pick up a
                     second or third language. Cynthia has excellent rapport with her students, makes excellent use of
                     curricular resources and demonstrates exquisite skills, as a language teacher, to ensure that her
                     students are progressing steadily in their lessons. Lia has had classes at Cynthia's school and
                     later using internet. We are very impressed and inspired by Cynthia's work. We recommend her very
                     highly."<b>-Jaan ( Father to Lia 11, Toronto)</b>
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     "I have been learning Mandarin from Cynthia for about 5 years. We learned Mandarin by reading
                     books, playing games, singing songs, working in our Mandarin work books, practicing writing
                     characters and just having a conversation in Mandarin. Now I'm continuing to learn Mandarin over
                     the internet. I have made many new friends throughout the years learning Mandarin. I have very much
                     enjoyed the time spent and am still spending with Cynthia and all the other girls in my group. I
                     hope to continue improving my Mandarin skills for a few more years to come.It's a lot of fun!"
                     <b> -Miriam Hanson (10 year old, Toronto)</b>
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     "My daughter attended Mandarin classes with Cynthia for 5 or 6 years. Cynthia is a caring, creative
                     and dedicated teacher. She adjusts the learning strategies so they are appropriate for each age.
                     Early years more games and songs, later years more structured and with excellent tools. I would
                     recommend her to anyone interested in learning basic Mandarin."
                     <b>-Kathy S. (Mother to Robin 11, Toronto)</b>
                  </Typography>
               </CardContent>
            </Card>
         </Stack>
      </Container>
   );
}

export default AboutUs;
