import {Card, CardContent, Container, Grid, Typography} from "@mui/material";
import React from "react";

function Instructors() {
   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            Our Instructors
         </Typography>
         <Grid container direction="row" alignItems="stretch" rowGap={1}>
            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Stella Li
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Stella graduated with Bachelor of Chinese literature from NanKai University in China and Master
                        degree of Education in University of Toronto. She is a talented and creative teacher who is
                        passionate about teaching Chinese language to the western world. Her vivid teaching methods are
                        well accepted by all her students. In her class, fun games, challenging tasks occupies students
                        from the beginning to the end.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Miranda Wang
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Miranda is a certified teacher for teaching Chinese as foreign language in China. She has more
                        than 15 years of teaching experience in China, Singapore and Canada. Miranda teaches both adult
                        and children classes. She always customize a learning plan for each student to help them
                        achieving their learning goals. From her language instruction, students found their confidence
                        in Mandarin learning and made rapid progress.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Malinda Yu
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Malinda is a senior Mandarin instructor. She has more than 7 years of professional Mandarin
                        teaching experience and curriculum design. Her students have benefited from her rich knowledge
                        of Chinese culture and Mandarin language arts. Malinda is a patient, loving teacher who bonds to
                        her students naturally and closely. Her energetic, clear way of instruction engages students
                        throughout the lessons.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Tiffany Cheng
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Tiffany is a teacher beloved by her students. She is holding Master Degree in Biology. Tiffany
                        naturally love children and has amazing connection with her students. She is a gifted and
                        energetic teacher who always engage her students with class activities easily. In her classes,
                        students enjoy learning Mandarin through fun games and creatively designed activities.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Anna Chang
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Anna is a senior language teacher in both China and Canada. She was an University professor in
                        Dalian, China. Anna is a patient instructor who has unique methods to encourage and support her
                        students to succeed. She has amazing bond with her students. From Anna's instruction, students
                        find their confidence of learning Mandarin.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Renee Yu
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Renee graduated from University of Toronto, holding a Masters Degree in Education. Renee is an
                        energetic and talented young teacher who is passionate about teaching. She is well connected
                        with all her students. Renee can easily assess students' needs and adapts teaching plan
                        accordingly to improve students' learning results.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Jingjing Li
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Jingjing is a talented teacher who is capable to turn difficult subjects into simple matters.
                        Characters seems a difficult component in Mandarin learning but Jingjing's students enjoy
                        learning Chinese characters with her. She assesses each student's learning needs and adapts her
                        teaching plans to help students achieving their learning goals. Jingjing is holding bachelor
                        degree in English Language and Literature from Northwest University in China, and master degree
                        in Education from University of Toronto.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <Card sx={{maxWidth: 345}}>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Cynthia Xu
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Cynthia is a senior Mandarin teacher who has been teaching in Toronto since 2004. She is the
                        founder of All Easier Mandarin school and currently teaching online classes.
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
         </Grid>
      </Container>
   );
}

export default Instructors;
