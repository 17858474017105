import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AppBar, Button, Menu, MenuItem, Stack, Toolbar, Typography,Box } from "@mui/material";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { useAuth } from "../Firebase";
import ManuBar from "../ManuBar";
import SignOutButton from "../SignOut";

function Navigation() {
   const { authUser } = useAuth();
   return <div>{authUser ? <NavigationAuth /> : <NavigationNonAuth />}</div>;
}

const NavigationAuth = () => {
   const [adminEl, setAdminEl] = useState(null);
   const navigate = useNavigate();
   const openAdmin = Boolean(adminEl);

   const onAdminClick = (e) => setAdminEl(e.currentTarget);
   const onAdminClose = (e) => setAdminEl(null);
   const onClickAdminMenuItem = (item) => {
      setAdminEl(null);
      navigate(item);
   };
   return (
      <>
         <AppBar position="static">
            <Toolbar>
               <Box component="img" className="logo" alt="All Easier" src="/logo-v5.jpg" />
               <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                  All Easier 百 易
               </Typography>
               <Stack direction="row" spacing={2}>
                  <Button variant="text" color="inherit" component={NavLink} to={ROUTES.ACCOUNT}>
                     Account
                  </Button>
                  <Button
                     variant="text"
                     color="inherit"
                     id="admin-bt"
                     onClick={onAdminClick}
                     aria-controls={openAdmin ? "admin-menu" : undefined}
                     aria-haspopup="true"
                     aria-expanded={openAdmin ? "true" : undefined}
                     endIcon={<KeyboardArrowDownIcon />}
                  >
                     Admin
                  </Button>
                  <Menu
                     id="admin-menu"
                     anchorEl={adminEl}
                     open={openAdmin}
                     MenuListProps={{ "aria-labelledby": "admin-bt" }}
                     onClose={onAdminClose}
                  >
                     <MenuItem onClick={() => onClickAdminMenuItem(ROUTES.ADMIN_BOOKS)}>Books</MenuItem>
                     <MenuItem onClick={() => onClickAdminMenuItem(ROUTES.ADMIN_IMAGES)}>Images</MenuItem>
                     <MenuItem onClick={() => onClickAdminMenuItem(ROUTES.ADMIN_USERS)}>Users</MenuItem>
                  </Menu>

                  <SignOutButton />
               </Stack>
            </Toolbar>
         </AppBar>
         <ManuBar />
      </>
   );
};

const NavigationNonAuth = () => (
   <>
      <AppBar position="static">
         <Toolbar>
            <Box component="img" className="logo" alt="All Easier" src="/logo-v5.jpg" />
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
               All Easier 百 易
            </Typography>
            <Stack direction="row" spacing={2}>
               <Button variant="text" color="inherit" component={NavLink} to={ROUTES.SIGN_IN}>
                  Sign In
               </Button>
            </Stack>
         </Toolbar>
      </AppBar>
      <ManuBar />
   </>
);
export default Navigation;
