import {Card, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {useAuth} from "../Firebase";

function Online() {
   const {dbUser} = useAuth();
   console.log(`The signed in user from home page: ${JSON.stringify(dbUser)}.`);
   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            Online Learning
         </Typography>
         <Grid container spacing={2}>
            <Grid item xs={8}>
               <Card>
                  <CardMedia component="img" height="150" image="/online01.jpg" sx={{objectFit: "contain"}} />
                  <CardContent>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Better Chinese’s Online Learning system includes the Classroom Management tool, student’s Online
                        Learning, and the Online Lesson Planner. The Classroom Management System gives teachers greater
                        flexibility in working with all the available Better Chinese teaching resources to perform daily
                        tasks such as assigning at-home activities, viewing student performance records, and tracking
                        student progress. Student’s Online Learning System features online resources that include
                        animated lessons, stories, homework, songs and rhymes, as well as innovated learning tools such
                        as the voice recorder and writing pad that correspond with our curricular series. It also
                        entails over 1,400 animated stories to help students sharpen their Chinese listening and reading
                        comprehension abilities. Overall, online learning helps students develop their literacy skills
                        by creating an immersive context for language learning. The Online Lesson Planner helps teachers
                        plan, share, mange, and record daily lessons in a matter of minutes. The tool allows teachers to
                        automatically create customized class syllabi for daily classes as well for the entire academic
                        year using content from the Teacher’s Guide and other Better Chinese teaching resources such as
                        worksheets, writing sheets, assessments, and homework. Contact Us for online curriculum pricing!
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Contact Us for online curriculum pricing!
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={4}>
               <Card>
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div">
                        Orders and Enquiries
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        For product catalog click here
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        School orders click here
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Personal orders click here
                     </Typography>
                     <Typography gutterBottom variant="body1" color="text.secondary">
                        Questions? Please Contact us
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
         </Grid>
      </Container>
   );
}

export default Online;
