import { Card, CardContent, Container, Stack, Typography } from "@mui/material";
import React from "react";

function ContactUs() {
   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            Contact Us
         </Typography>
         <Stack spacing={2} direction="row">
            <Card>
               <CardContent>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Director: </b>Cynthia Xu
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Phone: </b>+1-647-993-0889
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b> Fax: </b>+1-866-998-1789
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Email:</b>cynthia.xu@gmail.com
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Website:</b>www.alleasier.com School
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Address:</b>Address: All Easier Mandarin School 365 Mayfield Ave, Toronto, ON M6S 3L3
                  </Typography>
                  <Typography gutterBottom variant="h5" color="text.secondary">
                     For general inquiry:
                  </Typography>
                  <Typography gutterBottom variant="body1" color="text.secondary">
                     <b>Email:</b>alleasier.info@gmail.com
                  </Typography>
               </CardContent>
            </Card>
         </Stack>
      </Container>
   );
}

export default ContactUs;
