import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
   Alert,
   Box,
   Button,
   FormControl,
   FormHelperText,
   IconButton,
   InputAdornment,
   InputLabel,
   OutlinedInput,
} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {useAuth} from "../Firebase";

function PasswordChange() {
   const navigate = useNavigate();
   const {authUser, dbUser, changePassword} = useAuth();
   const [passwordOne, setPasswordOne] = useState("");
   const [passwordTwo, setPasswordTwo] = useState("");
   const [error, setError] = useState(null);
   const [showPassword, setShowPassword] = useState(false);
   const isInvalid = passwordOne !== passwordTwo || passwordOne === "";
   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };
   const onPasswordChange = async (e) => {
      e.preventDefault();
      try {
         await changePassword(authUser, passwordOne);
         navigate(ROUTES.SIGN_IN);
      } catch (err) {
         setError("Something went wrong, Please try it later.");
      }
   };

   const onChangePasswordOne = (e) => {
      setPasswordOne(e.target.value);
   };
   const onChangePasswordTwo = (e) => {
      setPasswordTwo(e.target.value);
   };

   return (
      <Box sx={{width: 358}} m="auto">
         <h3>Welcome back, {dbUser?.username}</h3>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">*Password</InputLabel>
            <OutlinedInput
               id="outlined-adornment-password"
               type={showPassword ? "text" : "password"}
               value={passwordOne}
               onChange={onChangePasswordOne}
               endAdornment={
                  <InputAdornment position="end">
                     <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                     >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                     </IconButton>
                  </InputAdornment>
               }
               label="Password"
               size="small"
               required
               error={!passwordOne}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">*Password</InputLabel>
            <OutlinedInput
               id="outlined-adornment-password"
               type={showPassword ? "text" : "password"}
               value={passwordTwo}
               onChange={onChangePasswordTwo}
               endAdornment={
                  <InputAdornment position="end">
                     <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                     >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                     </IconButton>
                  </InputAdornment>
               }
               label="Password"
               size="small"
               required
               error={!passwordTwo}
            />
         </FormControl>
         <FormControl sx={{m: 1, width: "35ch"}} variant="outlined">
            <Button onClick={onPasswordChange} disabled={isInvalid}>
               Reset Password
            </Button>
         </FormControl>
         <FormHelperText>*Fields are required.</FormHelperText>
         {error ? <Alert severity="error">{error}</Alert> : ""}
      </Box>
   );
}
export default PasswordChange;
