import * as DB from "../components/Firebase/firestore";

export const ENTITY = "users";

export function onColChange(doChange) {
   return DB.onChange(getUserColRef(), doChange);
}
export function onDocChange(id, doChange) {
   // console.log("From Users:" + id);
   return DB.onChange(getUserDocRef(id), doChange);
}
export function getUserColRef() {
   return DB.getColRef(ENTITY);
}

export function getUserDocRef(id) {
   return DB.getDocRef(ENTITY, id);
}

export async function add(id, user) {
   return DB.addWithId(ENTITY, id, user);
}

export async function getById(id) {
   return DB.getById(ENTITY, id);
}

export async function getUsers() {
   return DB.get(ENTITY);
}
// Updates receipt with @docId with given information.
export async function update(id, data) {
   return DB.update(ENTITY, id, data);
}

// Deletes receipt with given @id.
export async function remove(id) {
   DB.remove(ENTITY, id);
}
