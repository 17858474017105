export const HOME = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const ACCOUNT = "/account";
export const ADMIN_USERS = "/admin/users";
export const ADMIN_BOOKS = "/admin/books";
export const ADMIN_IMAGES = "/admin/images";
export const ABOUTUS = "/aboutus";
export const CONTACTUS = "/contactus";
export const CONSULTING = "/consulting";
export const PASSWORD_FORGET = "/pw-forget";
export const OUR_INSTRUCTORS = "/ourinstructors";
export const SCHOOL_GALLERY = "/gallery/:type";
export const SCHOOL_EVENTS = "/gallery/School-Events";
export const OUR_CLASSES = "/gallery/Our-Classes";
export const SUMMERCAMP = "/gallery/Summer-Camp";
export const BOOKS = "books/:level";
export const BOOKS_PRESCHOOL = "/books/Preschool-to-Elementary";
export const BOOKS_ELEMENTARY = "/books/Elementary";
export const BOOKS_MIDDLESCHOOL = "/books/Middle-School";
export const BOOKS_HIGHSCHOOL = "/books/High-School";
export const BOOKS_ONLINE = "/books-online";
export const BOOKS_OTHERS = "/books-others";
