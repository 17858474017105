import {Alert, Box, Button, FormControl, Grid, MenuItem, TextField} from "@mui/material";
import React, {useState} from "react";
import {v4 as uuidv4} from "uuid";
import * as DBImages from "../../entities/images";
import * as Storage from "../Firebase/firebaseStorage";

const init_image = {
   caption: null,
   type: "Our Classes",
};

const path = "images/";
function ImageEdit() {
   const [image, setImage] = useState(init_image);
   const [imageFile, setImageFile] = useState(null);

   const [error, setError] = useState("");
   const isInvalid = image.caption === "" || image.type === "";

   const onFileChoose = (event) => {
      setImageFile(event.target.files[0]);
   };

   const onChange = (e) => {
      console.log(e.target.name + ":" + e.target.value);
      setImage({
         ...image,
         [e.target.name]: e.target.value,
      });
   };

   const onSave = async () => {
      try {
         if (imageFile == null) return;
         const filename = uuidv4() + "." + imageFile.name.split(".").pop();
         console.log(filename);
         const result = await Storage.upload(`${path}${filename}`, imageFile);
         const downloadURL = await Storage.getURL(result.ref);
         const newImage = {...image, url: downloadURL};
         console.log(JSON.stringify(newImage));
         DBImages.add(newImage);
      } catch (err) {
         throw err;
      }
   };

   const onModifyData = async () => {
      try {
         DBImages.updateUrlForDevEnv();
      } catch (err) {
         throw err;
      }
       return true;
   };

   return (
      <Box py={2} component="form">
         <Grid container rowGap={1}>
            <Grid item xs={8}>
               <TextField
                  fullWidth
                  name="caption"
                  label="Caption*"
                  type={"text"}
                  value={image.caption}
                  onChange={onChange}
               />
            </Grid>
            <Grid item xs={4}>
               <FormControl sx={{m: 0.5}} variant="outlined">
                  <label htmlFor="upload-image">
                     <input id="upload-image" accept="image/*" type="file" onChange={onFileChoose} />
                  </label>
               </FormControl>
            </Grid>
            <Grid item xs={8}>
               <TextField
                  fullWidth
                  name="type"
                  label="Type*"
                  type={"text"}
                  value={image.type}
                  onChange={onChange}
                  select
               >
                  <MenuItem value={"Our Classes"}>Our Classes</MenuItem>
                  <MenuItem value={"School Events"}>School Events</MenuItem>
                  <MenuItem value={"Summer Camp"}>Summer Camp</MenuItem>
               </TextField>
            </Grid>
            <Grid item xs={2}>
               <Button onClick={onSave} disabled={isInvalid}>
                  Save
               </Button>
            </Grid>
            {/* <Grid item xs={8}>
               <Button onClick={onModifyData} disabled={true}>
                  Modify data for Dev environment
               </Button>
            </Grid> */}
         </Grid>

         {error ? <Alert severity="error">{error}</Alert> : ""}
      </Box>
   );
}

export default ImageEdit;
