import {Container, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as DBBooks from "../../entities/books";
import BookListCom from "./BookListCom";

function BookListPage() {
   let {level} = useParams();
   const [loading, setLoading] = useState(true);
   const [books, setBooks] = useState(null);
   if (level.indexOf("-")) {
      level = level.split("-").join(" ");
   }
   useEffect(() => {
      console.log(level);
      getBooks();
   }, [level]);

   const getBooks = async () => {
      const result = await DBBooks.getByfield("level", level);
      setBooks(result);
      setLoading(false);
   };
   return (
      <Container>
         <Typography gutterBottom variant="h4" component="div">
            {level}
         </Typography>
         {loading ? <p>Loading</p> : <BookListCom books={books} />}
      </Container>
   );
}

export default BookListPage;
