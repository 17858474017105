import {Button, Card, CardActions, CardContent, CardMedia, Grid, MenuItem, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";

function BookCard({book}) {
   const [bookPrice, setBookPrice] = useState(null);

   useEffect(() => {
      if (book.prices) {
         const keys = Object.keys(book.prices);
         console.log(keys[0]);
         setBookPrice(book.prices[keys[0]]);
      }
   }, []);
   const onChange = (e) => {
      console.log(e.target.name + ":" + e.target.value);
      setBookPrice(e.target.value);
   };

   return (
      <Card sx={{maxWidth: 345}}>
         <Typography gutterBottom variant="subtitle1" component="div">
            {book.name}
         </Typography>
         {book.prices ? (
            <TextField fullWidth name="price" type={"text"} value={bookPrice} onChange={onChange} select>
               {Object.keys(book.prices).map((key) => (
                  <MenuItem key={key} value={book.prices[key]}>{`${key} ${book.currency}${book.prices[key]}`}</MenuItem>
               ))}
            </TextField>
         ) : (
            <Typography gutterBottom variant="subtitle2" component="div">
               Price: {book.currency}${book.price}
            </Typography>
         )}
         <CardMedia component="img" height="150" image={book.url} sx={{objectFit: "contain"}} />
         <CardContent>
            <Typography gutterBottom variant="caption" component="div">
               {book.description}
            </Typography>
         </CardContent>
         <CardActions disableSpacing>
            <Button aria-label="add to favorites">Add to Cart</Button>
         </CardActions>
      </Card>
   );
}

function BookListCom({books}) {
   return (
      <Grid container spacing={2} rowGap={1}>
         {books.map((book) => (
            <Grid item xs={4} key={book.id}>
               <BookCard book={book} />
            </Grid>
         ))}
      </Grid>
   );
}

export default BookListCom;
