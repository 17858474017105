import {Breadcrumbs, Container, Link} from "@mui/material";
import React from "react";
import {useLocation} from "react-router-dom";

const urlMaping = new Map([
   ["/signup", "Sign Up"],
   ["/signin", "Sign In"],
   ["/account", "Account"],
   ["/admin/users", "Admin / Users"],
   ["/admin/books", "Admin / Books"],
   ["/admin/images", "Admin / Images"],
   ["/aboutus", "About Us"],
   ["/contactus", "Contact Us"],
   ["/consulting", "Consulting"],
   ["/pw-forget", "Account / Password Forgot"],
   ["/ourinstructors", " Our Instructors"],
   ["/gallery/School-Events", "School Events"],
   ["/gallery/Our-Classes", "Our Classes"],
   ["/gallery/Summer-Camp", "Summer Camp"],
   ["/books/Preschool-to-Elementary", "Books / Preschool to Elementary"],
   ["/books/Elementary", "Books / Elementary"],
   ["/books/Middle-School", "Books / Middle School"],
   ["/books/High-School", "Books / High School"],
   ["/books-online", "Books / Online Material"],
   ["/books-others", "Books / Supplementary"],
]);

function BreadcrumbsBar() {
   const url = useLocation();
   const name = urlMaping.get(url.pathname);
   return (
      <Container sx={{mb: 1}}>
         {name ? (
            <Breadcrumbs>
               <Link underline="none" href="/">
                  Home
               </Link>
               <Link underline="none" href="#">
                  {urlMaping.get(url.pathname)}
               </Link>{" "}
            </Breadcrumbs>
         ) : (
            ""
         )}
      </Container>
   );
}
export default BreadcrumbsBar;
